<template>
	<section class="section-wrapper" id="hero">
		<base-container>
			<base-heading tag="h1" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" class="title" v-animate="'slideLeft'"/>
			<div class="hero-wrapper">
				<div class="hero-content" v-animate="'slideRight'">
					<cms-editor-text :value="`${prefix}.section_text`" :props="{variant: 'paragraph', tag:'div'}"/>
					<cms-image :value="`${prefix}.text_image`"
							:base="{width: 263}"
							:md="{width: 368, height: 57}"
							class="text-img"
                            lazy
						/>
					<cms-multi-section class="section-elements" :value="`${prefix}.section_content`"/>
				</div>
				<figure class="img-wrapper" v-animate="'slideLeft'">
					<cms-image :value="`${prefix}.image`"
						lazy
						:base="{width: 330}"
						:xl="{width: 690, height: 341}"
						class="hero-image"
					/>
					<div class="svg-wrapper">
						<base-svg name="logo_color"/>
					</div>
				</figure>
			</div>
		</base-container>
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	components: {
		CmsMultiSection,
		BaseHeading
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
    background: $gray-300;
    @include media-breakpoint-up(xl) {
        background: $white;
    }
	padding: 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 8rem 0 10rem 0;
	}
	.hero-wrapper {
		display: grid;
		gap: 4rem;
		@include media-breakpoint-up(lg) {
			gap: 3rem;
			grid-template-columns: 1fr 0.7fr;
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: 1.3fr 1fr;
		}
		@include media-breakpoint-up(xxl) {
			grid-template-columns: 0.7fr 1fr;
		}

		.hero-content {
			height: 100%;
			display: flex;
			flex-direction: column;

			.text-img {
				margin-top: 4rem;
				@include media-breakpoint-up(lg) {
					margin-top: 2rem;
				}
			}
		}
	}
	.img-wrapper {
		position: relative;
		margin: 0;
        min-width: 50px;
		@include media-breakpoint-up(lg) {
			justify-self: end;
		}
		::v-deep .base-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.svg-wrapper {
			position: absolute;
			bottom: 50%;
			left: 50%;
			transform: translate(-50%, 50%);
			padding: 2.8rem;
			@include media-breakpoint-up(xl) {
				transform: none;
				bottom: -2rem;
				left: -3rem;
				padding: 4.5rem;
			}
			&::before {
				content: '';
				position: absolute;
				background: $white;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				box-shadow: 0 3px 6px rgba($black, 0.16);
				border-radius: 2px;
				z-index: 0;
			}
		}
		::v-deep .svg-icon {
			height: 3.2rem;
			position: relative;
			z-index: 2;
			@include media-breakpoint-up(xl) {
				height: 5.2rem;
			}
		}
	}
}
</style>
<style lang="scss">
#app.de .section-wrapper .hero-wrapper {
	@include media-breakpoint-up(xl) {
		gap: 4rem 6rem;
	}
}
</style>
